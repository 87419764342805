<template>
  <div class="mobile-menu d-sm-none" v-if="isProductDetailPage">
    <div class="row">
      <div class="col-2 pe-0">
        <a href="javascript:void(0)" class="product-buttons m-0" @click.prevent="chatCustomerService(currentProduct)">
          <button class="btn btn-outline-solid p-2 w-100">
            <vue-feather type="message-circle"></vue-feather>
          </button>
        </a>
      </div>
      <div class="col-2 pe-0">
        <a href="javascript:void(0)" @click.prevent="addToWishlist(currentProduct)"
          class="wishlist product-buttons m-0">
          <button class="btn btn-outline-solid p-2 w-100">
            <vue-feather type="heart"></vue-feather>
          </button>
        </a>
      </div>
      <div class="col-8">
        <div class="product-buttons m-0" v-if="!currentProduct">
          <button :disabled="true" id="cartEffect" class="btn btn-solid hover-solid btn-animation w-100">
            <div class="text-center">
              <div class="spinner-border spinner-border-sm" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </button>
        </div>
        <template v-if="currentProduct">
          <div v-if="addToCartEligibility" class="product-buttons m-0">
            <button id="cartEffect" class="btn btn-solid hover-solid btn-animation w-100" data-bs-toggle="modal" data-bs-target="#myModal">
              <i class="fa fa-shopping-cart"></i>
              <span>{{ useRuntimeConfig().public.const.Addtocart }}</span>
            </button>
          </div>
          <div v-else class="mt-3 text-danger text-center">Stok Habis (Tidak Tersedia)</div>
        </template>
      </div>
    </div>
  </div>
  <cart-mobile-menu-modal :product="currentProduct" />

  <div class="mobile-menu d-sm-none" v-if="!isProductDetailPage">
    <ul>
      <li>
        <nuxt-link to="/" :class="$route.path === '/' ? 'active' : ''">
          <vue-feather type="home"></vue-feather>
          <span>Home</span>
        </nuxt-link>
      </li>
      <li v-if="isAuthenticated">
        <nuxt-link to="/preorder" :class="$route.path === '/preorder' ? 'active' : ''">
          <vue-feather type="clock"></vue-feather>
          <span>Preorder</span>
        </nuxt-link>
      </li>
      <li v-if="isAuthenticated">
        <nuxt-link to="/cart" :class="$route.path === '/cart' ? 'active' : ''">
          <vue-feather type="shopping-bag"></vue-feather>
          <span>Keranjang</span>
        </nuxt-link>
      </li>
      <li v-if="isAuthenticated">
        <nuxt-link to="/transaction" :class="$route.path === '/transaction' ? 'active' : ''">
          <vue-feather type="package"></vue-feather>
          <span>Transaksi</span>
        </nuxt-link>
      </li>
      <li v-if="isAuthenticated">
        <nuxt-link to="/profile" :class="$route.path === '/profile' ? 'active' : ''">
          <vue-feather type="user"></vue-feather>
          <span>Akun</span>
        </nuxt-link>
      </li>
      <li v-if="!isAuthenticated">
        <nuxt-link to="/auth/register" :class="$route.path === '/auth/register' ? 'active' : ''">
          <vue-feather type="user-plus"></vue-feather>
          <span>Daftar</span>
        </nuxt-link>
      </li>
      <li v-if="!isAuthenticated">
        <nuxt-link to="/auth/login" :class="$route.path === '/auth/login' ? 'active' : ''">
          <vue-feather type="log-in"></vue-feather>
          <span>Masuk</span>
        </nuxt-link>
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import VueFeather from "vue-feather";
import { ProductItem } from "~/contract/vo/ProductRelated.vo";
import { useAuthStore } from "~/store/auth";
import { useCartStore } from "~/store/cart";
import { useProfileStore } from "~/store/profile";
import { useWishlistStore } from "~/store/wishlist";
import { useClickStore } from "~~/store/clickEvents";
import { useUserDashboardStore } from "~~/store/userDashboard";
import { useNavigationStore } from "~/store/navigation";
import { PageType } from "~/contract/enum/NavigationRelated.enum";
import CartMobileMenuModal from "~/layout/elements/modals/cartMobileMenuModal.vue";
import { useConstantStore } from "~/store/constant";
import { ConstantKey } from "~/contract/enum/ConstantRelated.enum";
import { useProductCustomStore } from "~/store/productCustom";
import { storeToRefs } from "pinia";
import { ProductStockStatus } from "~/contract/enum/ProductRelated.enum";

const cartStore = useCartStore();
const wishlistStore = useWishlistStore();
const clickStore = useClickStore();
const profileStore = useProfileStore();
const authStore = useAuthStore();
const navigationStore = useNavigationStore();
const constantStore = useConstantStore()
const productCustomStore = useProductCustomStore();

const user = computed(() => profileStore.identity);
const isAuthenticated = computed(() => authStore.authenticated);
const currentProduct = computed(() => navigationStore.currentProduct);

const { selectedVariantOption, productLoading } = storeToRefs(productCustomStore);

const isProductDetailPage = computed(() => {
  return navigationStore.currentPage == PageType.PRODUCT_DETAILS;
});
const companyPhone = ref<string>("")
const parsedPhoneToWhatsapp = computed(() => {
  if (!companyPhone.value) return null
  return companyPhone.value.replaceAll('-', '').replaceAll(' ', '').replaceAll('+', '')
})
const addToCartEligibility = computed(() => {
  const productHasVariant = currentProduct.value && currentProduct.value.variant && currentProduct.value.variant.variantOption.length > 0 ? true : false;
  if (productHasVariant) {
    return selectedVariantOption.value && selectedVariantOption.value?.productVariantStockStatus != ProductStockStatus.UNAVAILABLE;
  } else {
    return currentProduct.value?.productStockStatus != ProductStockStatus.UNAVAILABLE;
  }
});

// const props = defineProps({
//   isProductDetailPage: {
//     type: Boolean,
//     default: false,
//   },
// });

// const isProductDetailPage = toRef(props, "isProductDetailPage");

const openCategory = () => {
  clickStore.toggleCategoryMobileMenu();
};
const addToWishlist = (product: ProductItem | undefined) => {
  if (product) {
    const productId = product.id;
    let variantOptionId = selectedVariantOption.value.variantOptionId || null;
    useWishlistStore().addToWishList(productId, variantOptionId);
  }
};

const chatCustomerService = (product: ProductItem) => {
  if (parsedPhoneToWhatsapp.value) {
    let queryParam = new URLSearchParams({
      phone: parsedPhoneToWhatsapp.value,
      text: `Halo, saya tertarik dengan produk ${product.productName} (${window.location.origin + window.location.pathname}). Bisa beri informasi mengenai spesifikasi, harga, dan ketersediaannya? Terima kasih!`,
      type: 'phone_number'
    }).toString()
    window.open(
      `https://api.whatsapp.com/send/?${queryParam}`,
      '_blank'
    )
  }
};

onMounted(async () => await nextTick(async () => {
  companyPhone.value = await constantStore.getConstant(ConstantKey.COMPANY_PHONE) || ""
}))
</script>

<style lang="scss" scoped>
.mobile-menu {
  z-index: 3;
}

.btn-outline-solid {
  color: var(--theme-color);
  border-color: var(--theme-color);
  border-radius: 10%;
}

.btn-outline-solid:hover {
  color: #fff;
  border-color: #fff;
  background-color: var(--theme-color);
}
</style>